'use client';

import { useRef, useLayoutEffect } from 'react';

// iOS and Android browsers trigger resize events on scroll, which
// can be visually troublesome for elements using vh units. This hook
// will supress only(hopefully) mobile chrome resize events.

export const useSuppressBrowserChromeResize = callback => {
  const lastInnerWidthRef = useRef();
  const callbackFn = useRef(callback);

  useLayoutEffect(() => {
    const onResize = force => {
      const { innerWidth, innerHeight } = window;
      if (
        force !== true &&
        window.screen.width === innerWidth &&
        lastInnerWidthRef.current === innerWidth
      ) { return; }
      lastInnerWidthRef.current = innerWidth;
      if (callbackFn.current) callbackFn.current(innerWidth, innerHeight);
    };
    onResize(true);
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);
};
