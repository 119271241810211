'use client';

import React, { useRef, useLayoutEffect, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SmartLink } from 'src/tapestry/core/links/smart-link';
import { classNames } from 'src/utils/css';
import { translate } from 'src/utils/localization';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { useContentHubContentCollectionContext } from 'src/templates/content-hub/components/ContentHubContentCollectionContext';
import { legacyFontCondensed_ } from 'src/styles/legacy/futura-pt-condensed.module.scss';
import { ArticleObject } from '../ArticleObject';
import { ImageExpand } from '../ImageExpand';
import { FeaturedInterface } from '../FeaturedInterface';
import { useSuppressBrowserChromeResize } from '../SuppressChromeResizeHook';
import { UnderlineButton } from '../UnderlineButton';
import './MagDualColumn.scss';

gsap.registerPlugin(ScrollTrigger);

export const MagDualColumn = ({
  mainFeaturedArticles,
  firstFeaturedArticlesSection,
  secondFeaturedArticlesSection,
  stuckFirstFeaturedSection = true,
  featureSectionTitle,
  latestSectionLabel = '',
  latestSectionLead = '',
  latestSectionBody = '',
  latestSectionCta = {},
  showFeaturedInterface = true,
  noDesc,
  featuredArticlesNoDesc = true,
}) => {
  const { locale } = useTranslationContext();

  const leftColumnRef = useRef();
  const rightColumnRef = useRef();
  const cachedDimensions = useRef();
  const scrollerTween = useRef();
  const isMobileLayout = useRef();
  const { contentCollectionSlug } = useContentHubContentCollectionContext();

  useSuppressBrowserChromeResize((width, height) => {
    isMobileLayout.current = width <= 960;
    // methods that cause reflow, so only call when needed.
    cachedDimensions.current = {
      winHeight: height,
      realLeftHeight: leftColumnRef.current
        ? Math.round(leftColumnRef.current.getBoundingClientRect().height)
        : 0,
      realRightHeight: rightColumnRef.current
        ? Math.round(rightColumnRef.current.getBoundingClientRect().height)
        : 0,
    };

    // for detecting height when column height changes, usually due to images loading.
    cachedDimensions.current.lastLeftHeight = cachedDimensions.current.realLeftHeight;

    // reset gsap & scrollTrigger
    if (scrollerTween.current?.scrollTrigger) scrollerTween.current.scrollTrigger.kill();
    if (scrollerTween.current) scrollerTween.current.kill();

    scrollerTween.current = gsap.timeline({
      duration: 1,
      scrollTrigger: {
        trigger: rightColumnRef.current,
        start: 'top top',
        end: `bottom top`,
        scrub: true,
        onUpdate: self => {
          if (!isMobileLayout.current && scrollerTween.current) {
            // parallax images use javascript to calculate article preview dimensions relative to
            // viewport height, so we need to check latest column heights to make sure they haven't
            // changed. Ideally we don't check element rectangle every scroll because of reflow
            // calculations, but I'm not seeing a consistent way of checking cached variables exclusively.
            if (
              cachedDimensions.current.lastLeftHeight !==
              leftColumnRef.current.getBoundingClientRect().height
            ) {
              cachedDimensions.current.lastLeftHeight = leftColumnRef.current.getBoundingClientRect().height;
              cachedDimensions.current.realLeftHeight = cachedDimensions.current.lastLeftHeight;
              cachedDimensions.current.realRightHeight = rightColumnRef.current.getBoundingClientRect().height;
            }

            gsap.set(leftColumnRef.current, {
              y:
                -self.progress *
                (cachedDimensions.current.realLeftHeight -
                  cachedDimensions.current.realRightHeight),
            });
          } else {
            // left column scroll y is static in mobile - only parallax move
            gsap.set(leftColumnRef.current, { y: 0 });
          }
        },
      },
    });
  });

  useLayoutEffect(() => {
    return () => {
      if (scrollerTween.current) {
        scrollerTween.current.scrollTrigger.kill();
        scrollerTween.current.kill();
      }
    };
  }, []);

  const FeatureContent = () => {
    const [interactionState, setInteractionState] = useState('inactive');

    return (
      <>
        {mainFeaturedArticles.map(article => {
          return (
            <ImageExpand key={`column-sticky-${article.magazineArticlePageId}`}>
              <SmartLink
                href={`/${contentCollectionSlug}/${article.magazineArticlePageId}`}
                className="pb-lg block"
                style={{ textDecoration: 'none' }}
                onMouseEnter={() => setInteractionState('active')}
                onMouseLeave={() => setInteractionState('inactive')}
              >
                <ArticleObject
                  className="mb-xs"
                  article={article}
                  ratio={3 / 2}
                  noDesc={featuredArticlesNoDesc}
                  parallax
                  feature
                  context="main"
                  contextDetail="main-left"
                  suppressLink
                />
                <div>
                  <UnderlineButton
                    className="mt-sm text-xxs uppercase tracking-doublewide font-medium"
                    linkText={translate('read-article', locale)}
                    manualState={interactionState}
                  />
                </div>
              </SmartLink>
            </ImageExpand>
          );
        })}
      </>
    );
  };

  return (
    <div ref={rightColumnRef} className="MagDualColumn">
      <div className="MagColumn MagColumn__Left relative text-center hidden md:block">
        <div ref={leftColumnRef} className="relative">
          <p className="hidden MagColumn__FeaturedLabel text-greyscale-black leading-loosest">
            {translate('featured', locale)}
          </p>

          <div className={`w-full ${stuckFirstFeaturedSection ? 'MagColumn__Stuck' : ''}`}>
            <FeatureContent />
          </div>
        </div>
        {showFeaturedInterface && <FeaturedInterface />}
      </div>
      <div className="MagColumn">
        {featureSectionTitle && (
          <div className="MagColumn__Lead">
            <h2 className="text-greyscale-black text-2xl">
              {translate(featureSectionTitle, locale)}
            </h2>
          </div>
        )}
        <div className="MagColumn__Latest">
          {firstFeaturedArticlesSection.map(article => {
            return (
              <ImageExpand key={article.magazineArticlePageId}>
                <ArticleObject
                  key={article.magazineArticlePageId}
                  article={article}
                  context="latest"
                  contextDetail="latest-right-1"
                  noDesc={noDesc}
                />
              </ImageExpand>
            );
          })}
        </div>
        {secondFeaturedArticlesSection && (
          <div>
            <div className="MagColumn__Inter text-greyscale-black">
              <div className="MagColumn__InterCopy mx-auto md:mx-inherit">
                <p className="text-xxs uppercase tracking-doublewide font-medium">
                  {latestSectionLabel}
                </p>
                <h2
                  className={classNames(
                    legacyFontCondensed_,
                    'uppercase tracking-tighter mt-md mb-sm text-4xl md:text-5xl'
                  )}
                >
                  {latestSectionLead}
                </h2>
                <p className="text-body mb-md">{latestSectionBody}</p>
                <UnderlineButton
                  className="text-xxs uppercase tracking-doublewide font-medium"
                  linkText={latestSectionCta.linkText}
                  linkUrl={latestSectionCta.linkUrl}
                />
              </div>
            </div>
            <div className="MagColumn__Latest">
              {secondFeaturedArticlesSection.map(article => {
                return (
                  <ImageExpand key={article.magazineArticlePageId}>
                    <ArticleObject
                      key={article.magazineArticlePageId}
                      article={article}
                      context="latest"
                      contextDetail="latest-right-2"
                      noDesc={noDesc}
                    />
                  </ImageExpand>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
