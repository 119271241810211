'use client';

import React from 'react';
import { translate } from 'src/utils/localization';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { ArticleObject } from '../ArticleObject';
import './PopularArticles.scss';

export const PopularArticles = ({
  popularRowUpper,
  popularList,
  popularRight,
  popularRowLower,
}) => {
  const { locale } = useTranslationContext();
  return (
    <>
      <div className="PopularRow">
        {popularRowUpper.map((article, index) => {
          return (
            <ArticleObject
              key={article.magazineArticlePageId}
              className={`cell-${index}`}
              article={article}
              context="popular"
              contextDetail="popular-row-1"
            />
          );
        })}
      </div>
      <div className="PopularArticles">
        {popularRight.map((article, index) => {
          return (
            <ArticleObject
              key={article.magazineArticlePageId}
              className={`PopularArticles__RightColumn cell-${index}`}
              article={article}
              context="popular"
              contextDetail="popular-right"
            />
          );
        })}
        <div className="PopularArticles__StickyCell lg:mr-xl">
          <div className="ArticlesList w-full">
            <div className="ArticlesList__Label text-greyscale-black">
              <p className="font-semibold text-2xl mb-0">
                {translate('popular-right-now', locale)}
              </p>
            </div>
            <ul className="list-none">
              {popularList.map(article => {
                return (
                  <li key={article.magazineArticlePageId}>
                    <ArticleObject
                      className="ArticleObject--SmallOverride"
                      smallVariant
                      article={article}
                      context="popular"
                      contextDetail="popular-small"
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="PopularRow">
        {popularRowLower.map((article, index) => {
          return (
            <ArticleObject
              key={article.magazineArticlePageId}
              className={`cell-${index}`}
              article={article}
              context="popular"
              contextDetail="popular-row-2"
            />
          );
        })}
      </div>
    </>
  );
};
