'use client';

import React from 'react';
import { translate } from 'src/utils/localization';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { useContentHubContentCollectionContext } from 'src/templates/content-hub/components/ContentHubContentCollectionContext';
import { ArticleObject } from '../ArticleObject';
import { UnderlineButton } from '../UnderlineButton';
import './CategoryList.scss';

export const CategoryList = ({
  articles = [],
  category = '',
  seeMoreTranslationKey = 'see-more-articles',
}) => {
  const boxLength = articles.length;
  const style = {
    '--box-count': `${boxLength}`,
  };

  const { locale } = useTranslationContext();
  const { contentCollection, contentCollectionSlug } = useContentHubContentCollectionContext();
  const categoryLink = `/${contentCollectionSlug}/${category}`;
  const showMagazineHeadings = () => contentCollection === 'magazine';

  return (
    <div className={`CategoryList CategoryList__${contentCollection}`}>
      <div className={`ws-container--${contentCollection} w-full flex justify-between`}>
        <div className="CategoryList__Head text-greyscale-black">
          {showMagazineHeadings() && (
            <p className="uppercase text-xs mb-0 font-medium uppercase text-greyscale-black tracking-doublewide">
              {translate('featured-topic', locale)}
            </p>
          )}
          <h2
            className={`font-sansbold font-bold leading-none lg:leading-tight mt-sm ${
              contentCollection === 'magazine' ? 'text-4xl lg:text-5xl' : 'text-3xl'
            }`}
          >
            {translate(category, locale)}
          </h2>
          {showMagazineHeadings() && (
            <p className="text-heading text-lg lg:text-xl mt-xs">
              {translate(`subhead-${category}`, locale)}
            </p>
          )}
        </div>
        <div className="self-end hidden sm:block">
          <UnderlineButton
            className="text-xxs font-semibold uppercase text-greyscale-black tracking-doublewide"
            linkText={translate(seeMoreTranslationKey, locale)}
            linkUrl={categoryLink}
          />
        </div>
      </div>
      <div className={`ws-container--${contentCollection} pr-0 mt-lg`}>
        <div className="CategoryList__Articles">
          <ul className="CategoryList__Grid list-none" style={style}>
            {articles.map(article => {
              return (
                <li key={article.magazineArticlePageId}>
                  <ArticleObject
                    noCategory
                    article={article}
                    context="category-list"
                    contextDetail={category}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className={`ws-container--${contentCollection} flex sm:hidden mt-lg`}>
        <UnderlineButton
          className="text-xxs font-semibold uppercase text-greyscale-black tracking-doublewide"
          linkText={translate(seeMoreTranslationKey, locale)}
          linkUrl={categoryLink}
        />
      </div>
    </div>
  );
};
