'use client';

import React, { useState, useLayoutEffect, useEffect } from 'react';
import gsap from 'gsap';
import { arrayShuffleSeededRandom } from 'src/utils/math';
import { isMobileScreen, isDesktopScreen } from 'src/utils/window';
import { PageSection } from 'src/tapestry/layout/page-section';
import { TLDRSubsribeFormInsert } from 'src/components/common/TLDRSubsribeFormInsert';
import { MAX_FEATURED_ARTICLES } from '../../_constants';
import { CategoryList } from '../CategoryList';
import { ExcerptCta } from '../ExcerptCta';
import { ImageProgressor } from '../ImageProgressor';
import { useLatestArticlesLists } from '../LatestArticleListHook';
import { LatestArticlesSticky } from '../LatestArticlesSticky';
import { useMagazineNavContext } from '../MagazineNavContext';
import { MagDualColumn } from '../MagDualColumn';
import { MagFooterCTA } from '../MagFooterCTA';
import { PopularArticles } from '../PopularArticles';
import { ThemeCta } from '../ThemeCta';

export const IndexPageBody = ({ data }) => {
  const { isNavOpen, setPageType } = useMagazineNavContext();
  const {
    page: content,
    page: {
      magazineCta: ctaContent,
    },
    latestArticles: allLatestArticles,
  } = data;
  const featuredArticles = allLatestArticles.slice(0, MAX_FEATURED_ARTICLES);
  const latestArticles = allLatestArticles.slice(MAX_FEATURED_ARTICLES);
  const articlesByCategory = {
    news: data.articlesCategoryNews,
    'money-diaries': data.articlesCategoryMoneyDiaries,
    'finance-for-humans': data.articlesCategoryFinanceForHumans,
    'money-and-the-world': data.articlesCategoryMoneyAndTheWorld,
  };

  const [isImageProgressorActive, setIsImageProgressorActive] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // get 5 random articles from latest 20, then remove duplicates
  const popularRange = latestArticles.slice(0, 20) || [];
  const popularShuffled = arrayShuffleSeededRandom(content.sys.id, popularRange);
  const [popularArticlesList] = useState(popularShuffled.slice(0, 5));

  // assigns article arrays for populating components in each device layout
  const [
    desktopLatestArticles,
    latestArticlesUpper,
    latestArticlesLower,
    popularArticlesUpper,
    popularArticlesRight,
    popularArticlesLower,
  ] = useLatestArticlesLists(
    latestArticles,
    [2, 3, 0, 0, 0, 0],
    [2, 4, 0, 3, 2, 3],
    [8, 6, 6, 0, 4, 0]
  );

  const featuredArticlesMobile = featuredArticles.slice(0, 3) || [];
  const featuredArticlesDesktop = featuredArticles.concat(desktopLatestArticles);

  useEffect(() => {
    setPageType('index');
  }, [setPageType]);

  useLayoutEffect(() => {
    // set initial value - if mobile ImageProgressor will hijack this value
    const onResize = () => {
      setIsMobile(isMobileScreen());
      setIsImageProgressorActive(!isDesktopScreen());
    };

    // prevents first frame blink
    const overEl = document.getElementsByClassName('over')[0];
    gsap.set(overEl, { autoAlpha: 1 });

    // preload cover
    const overlayEl = document.getElementsByClassName('IntroOverlay')[0];
    if (overlayEl) {
      gsap.to(overlayEl, {
        duration: 0.5,
        autoAlpha: 0,
        delay: 1,
        ease: 'power2.inOut',
      });
    }

    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <>
      <div className="over invisible opacity-0">
        <div className={isMobile ? '' : 'ws-container--magazine'}>
          <ImageProgressor
            className="ImageProgressorIndexOverride md:hidden"
            articles={featuredArticlesMobile}
            pageType="index"
            active={isImageProgressorActive && !isNavOpen}
          />
        </div>
        <div className="ws-container--magazine">
          <MagDualColumn
            mainFeaturedArticles={featuredArticlesDesktop.slice(0, 3)}
            firstFeaturedArticlesSection={featuredArticlesDesktop.slice(3, 7)}
            secondFeaturedArticlesSection={featuredArticlesDesktop.slice(7, 11)}
            featureSectionTitle="latest"
            latestSectionLabel={content.latestSectionLabel}
            latestSectionLead={content.latestSectionLead}
            latestSectionBody={content.latestSectionBody}
            latestSectionCta={content.latestSectionCta}
          />
          <LatestArticlesSticky
            articles={latestArticlesUpper}
            reverse={latestArticlesUpper.length < 6}
          >
            <ExcerptCta content={ctaContent.excerptCta} />
          </LatestArticlesSticky>
          {latestArticlesLower.length > 0 && (
            <LatestArticlesSticky articles={latestArticlesLower} reverse>
              <TLDRSubsribeFormInsert />
            </LatestArticlesSticky>
          )}
          <ThemeCta className="mt-xl" layoutType="full" />
          <PopularArticles
            popularRowUpper={popularArticlesUpper}
            popularList={popularArticlesList}
            popularRight={popularArticlesRight}
            popularRowLower={popularArticlesLower}
          />
        </div>
        <CategoryList category="news" articles={articlesByCategory.news?.slice(0, 10)} />
        <CategoryList
          category="money-diaries"
          articles={articlesByCategory['money-diaries']?.slice(0, 10)}
        />
        <div className="gradient">
          <CategoryList
            category="finance-for-humans"
            articles={articlesByCategory['finance-for-humans']?.slice(0, 10)}
          />
          <CategoryList
            category="money-and-the-world"
            articles={articlesByCategory['money-and-the-world']?.slice(0, 10)}
          />
        </div>
        <div className="gradient--bottom">
          <div className="ws-container--magazine">
            <PageSection className="overflow-hidden">
              <MagFooterCTA />
            </PageSection>
          </div>
        </div>
      </div>
      <div className="IntroOverlay fixed w-full h-full" />
    </>
  );
};
