'use client';

import React, { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { translate } from 'src/utils/localization';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import './FeaturedInterface.scss';

gsap.registerPlugin(ScrollTrigger);

export const FeaturedInterface = () => {
  const { locale } = useTranslationContext();
  const wrapperRef = useRef();
  const innerRef = useRef();
  const counterRef = useRef();

  useLayoutEffect(() => {
    let lastStep = 0;
    const tl = gsap.timeline({
      duration: 1,
      scrollTrigger: {
        trigger: '.MagDualColumn',
        start: 'top top',
        end: `bottom bottom`,
        onUpdate: e => {
          // might want to dynamic if the number ever changes from 3
          if (lastStep !== 1 && e.progress < 0.06) {
            if (lastStep !== 1) {
              counterRef.current.textContent = '01';
              lastStep = 1;
            }
          } else if (e.progress > 0.06 && e.progress < 0.518) {
            if (lastStep !== 2) {
              counterRef.current.textContent = '02';
              lastStep = 2;
            }
          } else if (e.progress > 0.518) {
            if (lastStep !== 3) {
              counterRef.current.textContent = '03';
              lastStep = 3;
            }
          }
        },
        onEnterBack: () => {
          // outside layout - fixed position
          wrapperRef.current.classList.add('ws-container--magazine');
          gsap.set('.FeaturedInterface', { position: 'fixed', bottom: 0 });
          gsap.set(innerRef.current, { width: '50%' });
        },
        onLeave: () => {
          // inside layout - returns child behavior
          wrapperRef.current.classList.remove('ws-container--magazine');
          gsap.set('.FeaturedInterface', { position: 'absolute', bottom: 0 });
          gsap.set(innerRef.current, { width: '100%' });
        },
      },
    });
    return () => {
      if (tl && tl.scrollTrigger) {
        tl.scrollTrigger.kill();
        tl.kill();
      }
    };
  }, []);

  return (
    <div ref={wrapperRef} className="FeaturedInterface fixed w-full ws-container--magazine">
      <div ref={innerRef} className="w-1/2 relative h-full font-semibold">
        <p className="FeaturedInterface__FeaturedLabel text-xs text-greyscale-black font-medium tracking-doublewide uppercase">
          {translate('featured', locale)}
        </p>
        <p className="FeaturedInterface__ScrollLabel text-xs text-greyscale-black font-medium tracking-doublewide uppercase">
          {translate('magazine-scroll', locale)}
        </p>
        <p className="FeaturedInterface__Counter text-xs text-greyscale-black font-medium tracking-doublewide uppercase">
          <span ref={counterRef}>01</span>
          <span className="FeaturedInterface__Bar bg-greyscale-black inline-block" />
          03
        </p>
      </div>
    </div>
  );
};
